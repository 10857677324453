import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Providing you with the Finest Bangladeshi Cuisine
      </Typography>

      <Typography variant="body1" paragraph>
        Recipes have been handed down through the generations and we are
        constantly trying to improve them whilst retaining the traditional
        qualities and flavours. Naturally we only ise fresh Chicken and Lamb and
        the spices are mixed separately so they retain their individual flavour.
        The chef will be delighted to cook your favourite dish. Please do not
        hesitate to ask the staff.
      </Typography>

      <Typography variant="body1" paragraph>
        Apart from this we have an extensive wine list, you will always find a
        welcoming atmosphere with staff who are only too pleased to help you in
        any possible way and to make your dining with us an occasion to
        remember.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
